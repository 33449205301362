@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import url("https://fonts.cdnfonts.com/css/itc-avant-garde-gothic-std-book");
@import url("https://fonts.cdnfonts.com/s/14411/itc-avant-garde-gothic-std-demi-589572a199962.woff");
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@600&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@500;600;700&family=Simonetta&display=swap');
@import url("./toast.css");
@import '@blueprintjs/icons/lib/css/blueprint-icons';


@font-face {
  font-family: "ITC Avant Garde Gothic Std Medium";
  src: local("ITC Avant Garde Gothic Std Medium"),
    url(./Resources/fonts/ITCAvantGardeStd-Md.ttf) format("truetype");
}

iframe {
  display: none !important;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.bp4-icon-double-caret-vertical {
  right: 2px !important;
}

.bp4-select::after,
.bp4-html-select .bp4-icon,
.bp4-select .bp4-icon {
  top: 5px !important;
}

.bp4-datepicker {
  padding: 10px !important;
}

.toggle-button-root {
  border: none !important;
  font-family: "IBM Plex Sans" !important;
  font-size: 10px !important;
  letter-spacing: 1.5px !important;
  color: white !important;
  margin-right: 2px !important;
}
.toggle-button-selected {
  background-color: #7565a1 !important;
  border: 1px solid #00cc6a !important;
  box-sizing: border-box;
  border-radius: 16px !important;
  color: white !important;
}

.toggle-button-scorecard-root {
  border: none !important;
  font-family: "ITC Avant Garde Gothic Std Book" !important;
  font-size: 14px !important;
  color: #1c4a70 !important;
  margin-right: 10px !important;
  padding-top: 15px !important;
  width: 70px;
  border-radius: 36px !important;
}

.toggle-button-scorecard-selected {
  background-color: rgba(0, 204, 106, 0.2) !important;
  border: 1px solid #00cc6a !important;
  box-sizing: border-box;
  border-radius: 36px !important;
  color: #00cc6a !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.flex {
  display: flex;
}

.flex-row {
  flex-direction: row;
}

.justify-center {
  justify-content: center;
}

.items-center {
  align-items: center;
}

.flex-1 {
  flex: 1 1 0%;
}

.mr-3 {
  margin-right: 0.75rem;
}

.-ml-4 {
  margin-left: -1rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-3 {
  margin-top: 0.75rem;
}

.ml-y-l {
  margin-right: 50px !important;
}

.mr-y-l {
  margin-left: 10px !important;
}

.apexcharts-yaxis {
  width: 50px !important;
}

.search .MuiInput-input {
  padding: 4px 0px 4px !important;
}

.order-table {
  width: 100% !important;
}

.order-table tbody td {
  height: 46px !important;
}

.order-table tbody tr td:first-child {
  width: 100px !important;
  height: 46px !important;
}

.order-table thead tr th {
  max-width: 300px;
  min-width: 150px;
}

.order-table thead tr th:first-child {
  width: 100px !important;
  max-width: 100px !important;
  min-width: 100px !important;
}

.order-table thead tr th:last-child {
  width: 70px !important;
  max-width: 70px !important;
  min-width: 70px !important;
}

.scrollbar::-webkit-scrollbar {
  width: 8px;
  height: 6px;
}

.scrollbar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #edf0f6;
}

.scrollbar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px #9ca3af;
}

.table-stick-header th {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1;
  background: #edf0f6;
}

.main-container {
  width: 100%;
  margin-left: auto;
  box-sizing: border-box;
  margin-right: auto;
  display: block;
  padding-bottom: 58px;
}

.advance-view-table thead th:first-child {
  position: sticky;
  left: 0;
}

.advance-view-table tbody tr th {
  position: sticky;
  left: 0;
}

.table-filter-btn {
  width: 30px;
  display: flex;
  flex-direction: column;
  margin-right: 0px;
  /* align: right; */
  justify-content: center;
  align-items: center;
}

.table-filter-btn .MuiButton-root {
  width: 44;
  min-width: 44;
}

.table-filter-btn .MuiButton-root .MuiButton-endIcon {
  margin-left: 0;
  margin-right: 0;
}

ul[role="listbox"] {
  width: 100% !important;
}

.custom-date-picker .MuiTooltip-tooltip {
  padding: 0px !important;
  max-width: 500px !important;
}

[data-testid="CheckBoxIcon"] {
  font-size: 20px !important;
}

.MuiDataGrid-toolbarContainer {
  padding: 15px !important;
}

.clamp-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.clamp-4 {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

@media (min-width: 600px) {
  .main-container {
    padding-left: 24px;
    padding-right: 24px;
  }
}

@media (min-width: 1570px) {
  .main-container {
    max-width: 1570px;
  }
}

@media (min-width: 1900px) {
  .main-container {
    max-width: 1850px;
  }
}

@media (min-width: 1900px) {
  .main-container {
    max-width: 1850px;
  }
}
